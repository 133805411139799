import type { UseQueryReturnType } from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef, Ref, UnwrapRef } from 'vue'
import { FetchError } from 'ofetch'
import type { components } from '~/schema'
import { AccommodationFullDetailsType } from '~/schema'

export type AccommodationId = string

export type AccommodationFullDetails =
  components['schemas']['AccommodationFullDetails']

export { AccommodationFullDetailsType as AccommodationType }

type UseAccommodationQueryResult = UseQueryReturnType<
  AccommodationFullDetails,
  FetchError
>

export function useAccommodationQuery<
  Suspense extends boolean | undefined,
  Result extends [Suspense] extends [true]
    ? Promise<UseAccommodationQueryResult>
    : UseAccommodationQueryResult,
>(
  accommodationId: MaybeRef<AccommodationId | undefined>,
  {
    enabled,
    suspense,
  }: { enabled?: MaybeRef<boolean>; suspense?: Suspense } = {},
): Result {
  const fetch = useApiFetch()
  const queryResult = useQuery<AccommodationFullDetails, FetchError>({
    enabled: computed(() => unref(enabled) ?? !!unref(accommodationId)),
    queryKey: ['accommodation', accommodationId] as const,
    staleTime: 24 * 60 * 60 * 1000, // 24h stale time
    queryFn({ signal, queryKey: [_, id] }) {
      return fetch<AccommodationFullDetails>(`/api/accommodations/${id}`, {
        signal,
      })
    },
    placeholderData: (previousData) => previousData,
  })
  if (!suspense) {
    onServerPrefetch(queryResult.suspense)
    return queryResult as Result
  }

  return new Promise<typeof queryResult>((resolve, reject) => {
    queryResult
      .suspense()
      .then(() => resolve(queryResult))
      .catch(reject)
  }) as Result
}

const CONTEXT_ID = 'accommodationId' as const

type AccommodationCtx<T = string | undefined> = {
  accommodationId: Ref<UnwrapRef<T>>
  setAccommodationId(id: string | undefined): void
}

export function useAccommodationIdProvider<
  D extends string | undefined,
  T extends [D] extends [string] ? string : string | undefined,
>({ defaultValue }: { defaultValue?: D } = {}) {
  const accommodationId = ref<T>(defaultValue as T)
  const ctx: AccommodationCtx<T> = {
    accommodationId,
    setAccommodationId(id: string | undefined) {
      ;(accommodationId as Ref<string | undefined>).value = id
    },
  }
  provide(CONTEXT_ID, ctx)
  return ctx
}

export function useAccommodationIdContext<
  T extends string | undefined = string | undefined,
>() {
  return inject<AccommodationCtx<T>>(CONTEXT_ID, {
    get accommodationId() {
      console.error('No Accommodation context provided')
      return ref<T>(undefined as T)
    },
    setAccommodationId() {
      console.error('No Accommodation context provided')
    },
  })
}
